import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
class BrandVoucher extends Component {

  goToVouchers = () => {
    window.open("/vouchers", "_blank");
  };

  getCountLabel = () => {
    if (!this.props.data.header.countLabel) return "";
    return {
      count: this.props.data.header.countLabel?.split("]]")?.[0]?.replace("[[", ""),
      label: this.props.data.header.countLabel?.split("]]")?.[1],
    };
  };

  render() {
    if (!this.props.data) return <></>;
    return (
      <React.Fragment>
        <Container fluid className="c-vkyc c-pad mb-2 pe-md-0">
          <Row>
            <Col className="order-2 order-md-1 col-md-6 pt-2 d-flex flex-column justify-content-center">
              <div className="brand_voucher_count_text mt-md-5 mt-3">
                <span style={{ fontWeight: "700" }}>
                  {this.getCountLabel().count}
                </span>
                {this.getCountLabel().label}
              </div>
              <div className="c-h1 c5-h1" style={{ marginBottom: "12px", marginTop: "12px" }}>
                {this.props.data.header.title}
              </div>
              <div className="c4-d1">{this.props.data.header.description}</div>
              <div className="pb-4">
                <Button
                  className="c5-button btn-pri"
                  style={{height: "52px"}}
                  onClick={this.goToVouchers}
                >
                  Explore Vouchers
                </Button>
              </div>
            </Col>
            <Col className="order-1 order-md-2 p-0 text-end d-none d-md-flex flex-row">
              <img alt="" src={this.props.assets[this.props.data.defaultImg]?.url} width="575px" height="403px" />
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#ECE0F4B3",
                }}
              ></div>
            </Col>
            <Col className="col-12 order-1 order-md-2 text-center d-md-none pt-5">
              <img
                alt=""
                src={this.props.assets[this.props.data.mobileImg]?.url}
                width="75%"
                height="auto"
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default BrandVoucher;
